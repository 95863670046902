/* body {
  background-color: #2596be;
  color: white;
  margin: 20px;
  font: 32px "Century Gothic";
} */

@import url("https://fonts.googleapis.com/css?family=Lato:100&display=swap");
@import url("https://fonts.googleapis.com/css?family=Righteous");
@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url("https://fonts.googleapis.com/css?family=Gochi+Hand");

.game {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.board-row {
  display: flex;
  flex-flow: row nowrap;
}

.square {
  background-color: #e78207;
  color: white;
  border: 1px solid #000000;
  padding: 0;
  font-size: 84px;
  text-align: center;
  width: 100px;
  height: 100px;
}

.square:focus {
  outline: none;
  background-color: #222;
}

.square:hover {
  background-color: #222;
}

.status {
  margin: 20px;
  font-size: 24px;
}

/* -------------------------------OTHER STYLINGS---------------------------------------------------- */
body {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  height: 100vh;
  color: white;
  margin: 20px;
  font: 32px "Century Gothic";
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* ***************************PLAY AGAIN BUTTON STYLE*********************************************** */
.container {
  width: 400px;
  height: 400px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.center {
  width: 180px;
  height: 60px;
  position: absolute;
}

.btn {
  width: 180px;
  height: 60px;
  cursor: pointer;
  background: transparent;
  border: 1px solid #91c9ff;
  outline: none;
  transition: 1s ease-in-out;
  margin-top: 200px;
}

svg {
  position: absolute;
  left: 0;
  top: 0;
  fill: none;
  stroke: #fff;
  stroke-dasharray: 150 480;
  stroke-dashoffset: 150;
  transition: 1s ease-in-out;
  margin-top: 200px;
}

.btn:hover {
  transition: 1s ease-in-out;
  background: #4f95da;
}

.btn:hover svg {
  stroke-dashoffset: -480;
}

.btn span {
  color: white;
  font-size: 18px;
  font-weight: 100;
}

/* **********************TITLE STYLE888888888888888888888888888888888888888888888 */
